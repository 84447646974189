<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Obat</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Master / Obat / Data Obat</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Obat"
          class="mr-2"
          @click="$router.push({ name: 'ObatAdd' })"
        />
        <Button
          :loading="isLoadingExport"
          icon="pi pi-file-excel"
          label="Export"
          class="p-button-outlined"
          @click="onExport"
        />
      </div>
      <grid-obat
        :items="items"
        :kategoriObatList="kategoriObatList"
        :loading="isLoading"
        :total="totalRecords"
        :totalMargin="totalMarginRecords"
        :summary="summary"
        :grid-options="options"
        :margin-grid-options="marginOptions"
        :margin-history="margin_history"
        :batchList="batch_list"
        @request="onRequestData"
        @marginRequest="onRequestMarginData"
        @edit="onEditData"
        @delete="onConfirmDeletion"
        @marginhistory="onGetMarginHistory"
        @batchlist="onGetBatchHistory"
      />
    </div>
    <Dialog
      header="Hapus Obat"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Obat <strong>{{ form.nama }}</strong> akan dihapus. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteData"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import ObatService from '@/services/ObatService'
import GridObat from '@/components/master/GridObat'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import ReferenceService from '@/services/ReferenceService'

export default {
  name: 'Obat',
  components: {
    GridObat,
    Hotkey,
  },
  data() {
    return {
      obatService: null,
      referenceService: null,
      dialogHapus: false,
      isLoading: false,
      isLoadingExport: false,
      items: [],
      kategoriObatList: [],
      form: {},
      totalRecords: 0,
      totalMarginRecords: 0,
      summary: {},
      options: {
        page: 1,
        rows: 10,
        sortField: 'nama',
        sortOrder: -1,
        filters: null,
      },
      marginOptions: {
        page: 1,
        rows: 5,
      },
      margin_history: [],
      batch_list: [],
    }
  },
  created() {
    this.obatService = new ObatService()
    this.referenceService = new ReferenceService()
  },
  mounted() {
    this.loadGridData()
    this.loadKategoriObat()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadGridData()
    },
    onRequestMarginData(id, e) {
      this.marginOptions = e
      this.onGetMarginHistory(id)
    },
    loadKategoriObat() {
      this.isLoading = true
      this.kategoriObatList = []

      this.referenceService
        .getKategoriObat()
        .then((res) => {
          const data = res.data.data
          data.forEach((item) => {
            this.kategoriObatList.push(item)
          })
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Kategori Obat', this)
        })
        .finally(() => (this.isLoading = false))
    },
    loadGridData() {
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoading = true
      this.obatService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          let data = res.data.data
          const listJenis = [
            { kode: 'paten', nama: 'Paten' },
            { kode: 'generik', nama: 'Generik' },
          ]
          data.forEach((item) => {
            let arrMargin = []

            arrMargin.push(
              {
                margin: 'A',
                margin_netto: item.margin.margin_netto_A,
                margin_bruto: item.margin.margin_bruto_A,
                margin_rp: item.margin.margin_A,
                harga_jual: item.margin.harga_jual_A,
                harga_jual_ppn: item.margin.harga_jual_ppn_A,
              },
              {
                margin: 'B',
                margin_netto: item.margin.margin_netto_B,
                margin_bruto: item.margin.margin_bruto_B,
                margin_rp: item.margin.margin_B,
                harga_jual: item.margin.harga_jual_B,
                harga_jual_ppn: item.margin.harga_jual_ppn_B,
              },
              {
                margin: 'C',
                margin_netto: item.margin.margin_netto_C,
                margin_bruto: item.margin.margin_bruto_C,
                margin_rp: item.margin.margin_C,
                harga_jual: item.margin.harga_jual_C,
                harga_jual_ppn: item.margin.harga_jual_ppn_C,
              },
              {
                margin: 'D',
                margin_netto: item.margin.margin_netto_D,
                margin_bruto: item.margin.margin_bruto_D,
                margin_rp: item.margin.margin_D,
                harga_jual: item.margin.harga_jual_D,
                harga_jual_ppn: item.margin.harga_jual_ppn_D,
              },
              {
                margin: 'E',
                margin_netto: item.margin.margin_netto_E,
                margin_bruto: item.margin.margin_bruto_E,
                margin_rp: item.margin.margin_E,
                harga_jual: item.margin.harga_jual_E,
                harga_jual_ppn: item.margin.harga_jual_ppn_E,
              },
              {
                margin: 'F',
                margin_netto: item.margin.margin_netto_F,
                margin_bruto: item.margin.margin_bruto_F,
                margin_rp: item.margin.margin_F,
                harga_jual: item.margin.harga_jual_F,
                harga_jual_ppn: item.margin.harga_jual_ppn_F,
              }
            )

            item.marginTable = arrMargin
            item['jenis_text'] = listJenis.find(
              (x) => x.kode === item.jenis
            ).nama
          })
          this.items = data
          this.totalRecords = res.data.total
          this.summary = res.summary
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Obat', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onEditData(data) {
      this.$router.push({ name: 'ObatEdit', params: { id: data.id } })
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteData() {
      this.obatService
        .delete(this.form.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Data Obat',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadGridData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Data Obat', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.$router.push({ name: 'ObatAdd' })
      }
    },
    onGetMarginHistory(id) {
      const page = this.marginOptions.page
      const perPage = this.marginOptions.rows
      this.obatService.getMarginHistory(id, page, perPage).then((res) => {
        this.margin_history = res.data.data.data
        this.totalMarginRecords = res.data.data.total
      })
    },
    onGetBatchHistory(id) {
      this.obatService.getBatchHistory(id).then((res) => {
        this.batch_list = res.data.data
      })
    },
    onExport() {
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters
      this.isLoadingExport = true
      this.obatService
        .export(sortField, sortOrder, filters)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'data-obat.xls')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Export Data Pabrik', this)
        })
        .finally(() => (this.isLoadingExport = false))
    },
  },
}
</script>
